<template>
  <Modal :open="open" title="Eliminar video">
    <template v-slot:closeiconmodal>
      <i class="tmlps-icn-cross h2"></i>
    </template>
    <template v-slot:titlemodal>
      <h5 class="tl-modal__title">Eliminar video</h5>
    </template>
    <p>¿Estás seguro de que deseas eliminar el video {{ video.id ? video.id : null }}?</p>

    <div class="mt-4 d-flex justify-content-center align-items-center">
      <button type="button" class="button button--secondary w-100 mr-1" @click="$emit('close')">
        Cancelar
      </button>
      <button type="button" class="button w-100 ml-1" @click="deleteVideo">
        Eliminar
      </button>
    </div>
  </Modal>
</template>

<script>
import videoService from '@/services/video';
import Errors from '@/util/Errors';
import Modal from '@/components/Modal';

export default {
  name: 'DeleteVideoModal',
  components: { Modal },
  props: {
    open: { type: Boolean, default: false },
    video: { type: Object },
  },
  data() {
    return {
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {},
  methods: {
    async deleteVideo() {
      this.loading = true;

      try {
        await videoService.deleteVideo(this.video.id).then(() => {
          this.loading = false;
          const notification = {
            type: 'success',
            message: 'El video ha sido eliminado exitosamente',
          };

          this.$store.dispatch('notification/add', notification, { root: true });

          this.$emit('delete-video');
          this.$emit('close');
        });
      } catch (error) {
        console.log(error);

        if (error.response && error.response.data) {
          if (error.response.status === 500) {
            return (this.error = 'Ocurrió un error al intentar eliminar el video.');
          }

          const jsonError = JSON.parse(await error.response.data.text());

          if (jsonError.error) {
            this.error = jsonError.error.message;
          }

          if (jsonError.errors) {
            const firstError = Object.keys(jsonError.errors)[0];
            this.error = jsonError.errors[firstError][0];
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.tl-modal

  &__title
    color: #242424
    font-size: 1rem
    font-weight: bold
    text-align: left
    padding-bottom: 1.25rem
    border-bottom: 1px solid #C1C1C1

.button
  background-color: var(--primary)
  padding: 0.75rem 3.5rem
  border-radius: 0.25rem
  color: white
  font-size: 0.875rem

  &--secondary
    background-color: rgba(74,98,212,0.2)
    padding: 0.75rem 3.5rem
    border-radius: 0.25rem
    color: #242424
    font-size: 0.875rem
</style>
