<template>
  <div class="profile-dashboard">
    <b-tabs card class="profile-options" v-model="tabIndex">
      <b-tab no-body :title="$t('lang.videos.msg')">
        <ProfileVideoTable :project="project" v-if="tabIndex == 0" />
      </b-tab>
      <b-tab no-body :title="$t('lang.bitacora.msg')">
        <ProfileLogTable :project="project" v-if="tabIndex == 1" />
      </b-tab>
      <b-tab no-body :title="$t('lang.contrasenia.msg')">
        <ProfileChangePasswordForm v-if="tabIndex == 2" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ProfileVideoTable from '@/components/ProfileVideoTable';
import ProfileLogTable from '@/components/ProfileLogTable';
import ProfileChangePasswordForm from '@/components/ProfileChangePasswordForm';

export default {
  name: 'ProfileDashboard',
  props: {
    project: { type: Object, required: true },
  },
  components: {
    ProfileVideoTable,
    ProfileLogTable,
    ProfileChangePasswordForm,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.profile-dashboard {
  padding: 0.5rem;

  @include media-breakpoint-up(md) {
    padding: 1rem;
  }
}

.profile-options {
  .card {
    font-family: 'Lato', sans-serif;

    &-header {
      background: transparent !important;
      border-bottom: none !important;

      .nav {
        &-link {
          color: #000;

          &:hover {
            border: none;
            font-weight: 700;
          }

          &.active {
            background: transparent;
            border: none;
            border-bottom: 5px solid var(--primary);
            font-weight: 700;
          }
        }
      }
    }
  }

  .tab {
    &-content {
      padding-top: 25px;
    }
  }
}
</style>
