<template>
  <div class="profile-video-table">
    <b-table
      striped
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="5"
      :busy="loading"
      show-empty
      primary-key="id"
      id="profile-video-table"
      responsive
      stacked="md"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> {{ $t('lang.cargando.msg') }}</strong>
        </div>
      </template>

      <template #empty>
        <h5 class="py-5"> {{ $t('lang.no_videos.msg') }}</h5>
      </template>

      <template #cell(created_at)="data">
        <div class="text-left">{{ data.item.created }}</div>
      </template>

      <template #cell(date_range)="data">
        <div class="text-left">{{ data.item.date_range }}</div>
      </template>

      <template #cell(user_email)="data">
        <div class="text-left">{{ data.item.creator ? data.item.creator.email : 'Usuario eliminado' }}</div>
      </template>

      <template #cell(status)="data">
        <div class="text-left">{{ statusLabel(data.item.status) }}</div>
      </template>

      <template #cell(available_for)="data">
        <div class="text-left">{{ data.item.available_for }} {{ txtDias }}</div>
      </template>

      <template #cell(actions)="row">
        <template v-if="row.item.status == 'success'">
          <button
            class="btn-circle-sm btn-line d-none d-md-inline-block"
            :key="row.item.id"
            @click="$router.push({ name: 'video-player', params: { id: row.item.id } })"
          >
            <i class="tmlps-icn-see-password h5"></i>
          </button>
          <button
            class="btn-circle-sm btn-line mx-4 d-none d-md-inline-block"
            @click="handleVideoDownload(row.item)"
          >
            <i class="tmlps-icn-download h5"></i>
          </button>
          <DeleteButton @click="handleDeleteVideo(row.item)" />
        </template>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      pills
      :per-page="5"
      size="sm"
      align="right"
      class="my-0"
      aria-controls="profile-video-table"
    ></b-pagination>

    <DeleteVideoModal
      :open="openDeleteVideoModal"
      :video="selectedVideo"
      @close="openDeleteVideoModal = false"
      @deleteVideo="fetchVideos"
    />
  </div>
</template>

<script>
import store from '@/store';
import Errors from '@/util/Errors';
import DeleteButton from '@/components/DeleteButton';
import DeleteVideoModal from '@/components/DeleteVideoModal';

export default {
  name: 'ProfileVideoTable',
  props: {
    project: { type: Object, required: true },
  },
  components: {
    DeleteButton,
    DeleteVideoModal,
  },
  data() {
    return {
      fields: [
        { key: 'created_at', label: this.$t('lang.creado.msg') },
        { key: 'date_range', label: this.$t('lang.periodo.msg') },
        { key: 'user_email', label: this.$t('lang.autor.msg') },
        { key: 'status', label: this.$t('lang.estatus.msg') },
        { key: 'available_for', label: this.$t('lang.disponible.msg') },
        { key: 'actions', label: '' },
      ],
      items: [],
      loading: false,
      errors: new Errors(),
      openDeleteVideoModal: false,
      currentPage: 1,
      totalRows: 0,
      selected: null,

      keyTable:23
    };
  },
  watch: {
    '$i18n.locale': function(){
      console.log('lol');
      this.fields = [
        { key: 'created_at', label: this.$t('lang.creado.msg') },
        { key: 'date_range', label: this.$t('lang.periodo.msg') },
        { key: 'user_email', label: this.$t('lang.autor.msg') },
        { key: 'status', label: this.$t('lang.estatus.msg') },
        { key: 'available_for', label: this.$t('lang.disponible.msg') },
        { key: 'actions', label: '' },
      ];
    }
  },
  computed: {
    selectedVideo() {
      return this.selected ? this.selected : {};
    },

    txtDias(){
      if(this.$i18n.locale == 'en'){
        return 'days'
      }else{
        return 'días'
      }
    }
  },
  methods: {
    async fetchVideos() {
      this.loading = true;
      try {
        await store.dispatch('project/fetchProjectVideos', this.project.id).then((res) => {
          console.log("🚀 ~ awaitstore.dispatch ~ res", res)
          this.items = res.data;
          this.totalRows = res.data.length;
        });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
          return;
        }
        this.errors.capture({ error: 'Ocurrió un error grave. Por favor intentalo más tarde.' });
        console.error(error);
        return;
      } finally {
        this.loading = false;
      }
    },
    handleViewVideo(video) {
      this.$router.push({ name: 'video-player', params: { id: video.id } });
    },
    async handleVideoDownload(video) {
      let blob = await fetch(video.url, { cache: 'no-cache' }).then((response) => response.blob());
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = video.filename;
      link.click();
    },
    handleDeleteVideo(video) {
      this.selected = video;
      this.openDeleteVideoModal = true;
    },
    statusLabel(status) {
      if(this.$i18n.locale == 'en'){
        return status;
      }
      if (status === 'pending') {
        return 'pendiente';
      }

      if (status === 'in-progress') {
        return 'en proceso';
      }

      if (status === 'success') {
        return 'generado';
      }

      return 'fallido';
    },
  },
  async created() {
    await this.fetchVideos();
  },
};
</script>

<style lang="scss">
.table {
  td {
    border: none;
  }

  thead {
    th {
      border-bottom: none;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
    }
  }

  th {
    border-bottom: none;
    border-top: none;
  }

  &-striped {
    tbody {
      td {
        &:last-child {
          text-align: center;
        }
      }
      tr {
        &:nth-of-type(odd) {
          background-color: rgba(74, 98, 212, 0.05);
        }
      }
    }
  }
}
</style>
