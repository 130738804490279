var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-dashboard" },
    [
      _c(
        "b-tabs",
        {
          staticClass: "profile-options",
          attrs: { card: "" },
          model: {
            value: _vm.tabIndex,
            callback: function($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex"
          }
        },
        [
          _c(
            "b-tab",
            { attrs: { "no-body": "", title: _vm.$t("lang.videos.msg") } },
            [
              _vm.tabIndex == 0
                ? _c("ProfileVideoTable", { attrs: { project: _vm.project } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { "no-body": "", title: _vm.$t("lang.bitacora.msg") } },
            [
              _vm.tabIndex == 1
                ? _c("ProfileLogTable", { attrs: { project: _vm.project } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { "no-body": "", title: _vm.$t("lang.contrasenia.msg") } },
            [_vm.tabIndex == 2 ? _c("ProfileChangePasswordForm") : _vm._e()],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }