var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-start justify-content-between"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-items-center",
                      staticStyle: { "flex-direction": "column" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-flex flex-column align-items-center"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-circle mb-1 px-0",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.back()
                                }
                              }
                            },
                            [_c("i", { staticClass: "tmlps-icn-back h3 m-0" })]
                          ),
                          _c("div", { staticClass: "small text-center" }, [
                            _vm._v(_vm._s(_vm.$t("lang.return.msg")))
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "d-flex align-items-start" }, [
                    _c("h4", { staticClass: "h4" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("lang.opciones.msg")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "d-flex align-items-start" })
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "profile" }, [
        _c(
          "div",
          { staticClass: "profile__sidebar" },
          [
            _c("ProjectInfo", {
              attrs: { project: _vm.project.project, user: _vm.user.user }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "profile__content" },
          [_c("ProfileDashboard", { attrs: { project: _vm.project.project } })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }