<template>
  <div class="project-info__container card-w-shadow">
    <div class="project-info__logo">
      <Logo :image-url="projectLogoUrl" :description="project.name" />
      <ChangeLogoButton :project="project" class="mt-2" />
    </div>

    <div class="project-info__data">
      <div class="project-info__label">{{ $t('lang.proyecto.msg') }}</div>
      <p>{{ project.name }}</p>

      <div class="project-info__label mt-2 mt-md-4">{{ $t('lang.correo.msg') }}</div>
      <p>{{ user.email }}</p>

      <div class="project-info__label mt-2 mt-md-4">{{ $t('lang.espacio.msg') }}</div>
      <p>
        {{ project.storage_space_used_kb | kb_to_gb }} GB {{ $t('lang.utilizado.msg') }} /
        {{ project.video_storage_limit_kb | kb_to_gb }} GB
      </p>
      <b-progress
        :value="project.storage_space_used_kb"
        :max="project.video_storage_limit_kb"
        class="progress-bar-profile"
      ></b-progress>
    </div>

    <div class="text-center mt-2">
      <b-button @click="$store.dispatch('auth/logout')" variant="link" class="card-link">
        {{ $t('lang.cerrar.msg') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo';
import ChangeLogoButton from '@/components/ChangeLogoButton';

export default {
  name: 'ProjectInfo',
  props: {
    project: { type: Object, required: true },
    user: { type: Object, required: true },
  },
  components: {
    Logo,
    ChangeLogoButton,
  },
  computed: {
    ...mapGetters('project', ['projectLogoUrl']),
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.project-info {
  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    justify-content: center;

    @include media-breakpoint-up(md) {
      height: 100%;
      flex-direction: column;
      padding: 1rem;
    }
  }

  &__logo {
    flex-grow: 0;
    text-align: center;
  }
  &__data {
    flex: 1;
    margin-left: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
  &__label {
    font-weight: bold;
  }
}

.card {
  &-footer {
    background: transparent;
    border: none;
    font-family: 'Prompt', sans-serif;
  }
}

.card-body {
  padding: 0.5rem;
}

.list {
  &-group {
    &-item {
      border: none;
      padding-left: 0;

      p {
        font-family: 'Prompt', sans-serif;
        font-weight: 400;
      }

      strong {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
      }
    }
  }
}
</style>
