<template>
  <AppLayout>
    <div class="profile">
      <div class="profile__sidebar">
        <ProjectInfo :project="project.project" :user="user.user" />
      </div>

      <div class="profile__content">
        <ProfileDashboard :project="project.project" />
      </div>
    </div>

    <template v-slot:actionbar>
      <div class="d-flex align-items-start justify-content-between">
        <div class="d-flex align-items-center" style="flex-direction: column">
          <div class="d-inline-flex flex-column align-items-center">
            <b-button class="btn-circle mb-1 px-0" variant="primary" @click="$router.back()">
              <i class="tmlps-icn-back h3 m-0"></i>
            </b-button>
            <div class="small text-center">{{$t('lang.return.msg')}}</div>
          </div>
        </div>

        <div class="d-flex align-items-start">
          <h4 class="h4"><strong>{{ $t('lang.opciones.msg') }}</strong></h4>
        </div>

        <div class="d-flex align-items-start"></div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import ProfileDashboard from '@/components/ProfileDashboard.vue';
import ProjectInfo from '@/components/ProjectInfo.vue';

export default {
  name: 'profile-home',
  components: {
    ProfileDashboard,
    ProjectInfo,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['project', 'user']),
  },
  methods: {
    init() {},
  },
  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.profile {
  height: 100%;
  overflow-y: scroll;
  padding: 1rem;
  background: #232323;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
  }

  &__sidebar {
    background: white;
    border-radius: 0.25rem;
  }

  &__content {
    margin-top: 2rem;
    flex: 1;
    background: white;
    border-radius: 0.25rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}
</style>
