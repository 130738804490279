var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-log-table" },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          items: _vm.items,
          fields: _vm.fields,
          "show-empty": "",
          responsive: "",
          stacked: "md"
        },
        scopedSlots: _vm._u([
          {
            key: "empty",
            fn: function() {
              return [
                _c("h5", { staticClass: "py-5" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang.no_registro.msg")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "cell(user)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(data.item.user.email))
                ])
              ]
            }
          },
          {
            key: "cell(action)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(data.item.action))
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }