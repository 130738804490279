<template>
  <div class="text-center d-inline-block">
    <button class="btn-circle-sm btn-line" @click="$emit('click')">
      <i class="tmlps-icn-delete h5"></i>
    </button>
    <div class="small text-center" v-if="showText">Eliminar</div>
  </div>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    showText: { type: Boolean, default: false },
  },
};
</script>
