<template>
  <div class="profile-log-table">
    <b-table striped :items="items" :fields="fields" show-empty responsive stacked="md">
      <template #empty>
        <h5 class="py-5"> {{ $t('lang.no_registro.msg') }}</h5>
      </template>

      <template #cell(user)="data">
        <div class="text-left">{{ data.item.user.email }}</div>
      </template>

      <template #cell(action)="data">
        <div class="text-left">{{ data.item.action }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import store from '@/store';
import Errors from '@/util/Errors';

export default {
  name: 'ProfileLogTable',
  props: {
    project: { type: Object, required: true },
  },
  data() {
    return {
      fields: [
        { key: 'created_at', label: this.$t('lang.fecha.msg') },
        { key: 'user', label: this.$t('lang.usuario.msg') },
        { key: 'action', label: this.$t('lang.accion.msg') },
      ],
      items: [],
      loading: false,
      errors: new Errors(),
    };
  },
  watch: {
    '$i18n.locale': function(){
      console.log('lol');
      this.fields = [
        { key: 'created_at', label: this.$t('lang.fecha.msg') },
        { key: 'user', label: this.$t('lang.usuario.msg') },
        { key: 'action', label: this.$t('lang.accion.msg') },
      ];
    }
  },
  methods: {
    async fetchLogs() {
      this.loading = true;

      try {
        await store.dispatch('project/fetchProjectLogs', this.project.id).then((res) => {
          this.items = res.data;
        });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
          return;
        }
        this.errors.capture({ error: 'Ocurrió un error grave. Por favor intentalo más tarde.' });
        console.error(error);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.fetchLogs();
  },
};
</script>
