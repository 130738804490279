var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-info__container card-w-shadow" }, [
    _c(
      "div",
      { staticClass: "project-info__logo" },
      [
        _c("Logo", {
          attrs: {
            "image-url": _vm.projectLogoUrl,
            description: _vm.project.name
          }
        }),
        _c("ChangeLogoButton", {
          staticClass: "mt-2",
          attrs: { project: _vm.project }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "project-info__data" },
      [
        _c("div", { staticClass: "project-info__label" }, [
          _vm._v(_vm._s(_vm.$t("lang.proyecto.msg")))
        ]),
        _c("p", [_vm._v(_vm._s(_vm.project.name))]),
        _c("div", { staticClass: "project-info__label mt-2 mt-md-4" }, [
          _vm._v(_vm._s(_vm.$t("lang.correo.msg")))
        ]),
        _c("p", [_vm._v(_vm._s(_vm.user.email))]),
        _c("div", { staticClass: "project-info__label mt-2 mt-md-4" }, [
          _vm._v(_vm._s(_vm.$t("lang.espacio.msg")))
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm._f("kb_to_gb")(_vm.project.storage_space_used_kb)) +
              " GB " +
              _vm._s(_vm.$t("lang.utilizado.msg")) +
              " / " +
              _vm._s(_vm._f("kb_to_gb")(_vm.project.video_storage_limit_kb)) +
              " GB "
          )
        ]),
        _c("b-progress", {
          staticClass: "progress-bar-profile",
          attrs: {
            value: _vm.project.storage_space_used_kb,
            max: _vm.project.video_storage_limit_kb
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-center mt-2" },
      [
        _c(
          "b-button",
          {
            staticClass: "card-link",
            attrs: { variant: "link" },
            on: {
              click: function($event) {
                return _vm.$store.dispatch("auth/logout")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("lang.cerrar.msg")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }