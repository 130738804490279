var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "btn-blue btn-blue-sm",
          attrs: { disabled: _vm.loading },
          on: { click: _vm.handleChangeImageLogo }
        },
        [_vm._v(_vm._s(_vm.$t("lang.cambiar.msg")))]
      ),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/png, image/jpeg, image/jpg" },
        on: { change: _vm.handleSelectFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }