var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { open: _vm.open, title: "Eliminar video" },
      scopedSlots: _vm._u([
        {
          key: "closeiconmodal",
          fn: function() {
            return [_c("i", { staticClass: "tmlps-icn-cross h2" })]
          },
          proxy: true
        },
        {
          key: "titlemodal",
          fn: function() {
            return [
              _c("h5", { staticClass: "tl-modal__title" }, [
                _vm._v("Eliminar video")
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", [
        _vm._v(
          "¿Estás seguro de que deseas eliminar el video " +
            _vm._s(_vm.video.id ? _vm.video.id : null) +
            "?"
        )
      ]),
      _c(
        "div",
        {
          staticClass: "mt-4 d-flex justify-content-center align-items-center"
        },
        [
          _c(
            "button",
            {
              staticClass: "button button--secondary w-100 mr-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "button",
            {
              staticClass: "button w-100 ml-1",
              attrs: { type: "button" },
              on: { click: _vm.deleteVideo }
            },
            [_vm._v(" Eliminar ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }