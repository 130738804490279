<template>
  <div class="container mt-4">
    <b-row class="justify-content-center">
      <b-col cols="12" md="3">
        <h6 class="text-center">{{$t('lang.texto_cambiar.msg')}}</h6>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4">
      <b-col cols="12" md="6">
        <form @submit.prevent="changePassword">
          <b-row class="justify-content-center">
            <b-col cols="12" class="mb-3">
              <PasswordInput
                :label="$t('lang.contrasenia_actual.msg')"
                v-model="form.old_password"
                :error="errors.first('password')"
              />
            </b-col>

            <b-col cols="12" class="mb-3">
              <PasswordInput
                :label="$t('lang.contrasenia_nueva.msg')"
                v-model="form.new_password"
                :error="errors.first('password')"
              />
            </b-col>

            <b-col cols="12" md="6">
              <button class="btn-primary w-100" :disabled="loading">
                {{ $t('lang.contrasenia_cambiar.msg') }}
              </button>
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import Errors from '@/util/Errors';
import PasswordInput from '@/components/PasswordInput';
export default {
  name: 'ProfileChangePasswordForm',
  components: { PasswordInput },
  data() {
    return {
      form: {
        old_password: null,
        new_password: null,
      },
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    changePassword() {
      this.loading = true;
      this.errors.clear();

      const formData = new FormData();
      formData.append('password', this.form.old_password);
      formData.append('new_password', this.form.new_password);

      store
        .dispatch('user/updatePassword', {
          userId: this.user.user.id,
          data: formData,
        })
        .then(() => {
          this.$store.dispatch(
            'notification/addSuccess',
            'La contraseña ha sido cambiada exitosamente.',
          );

          this.resetForm();
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            this.errors.capture(error.response.data.errors);
          }

          this.loading = false;
        });
    },
    resetForm() {
      this.form.old_password = null;
      this.form.new_password = null;
    },
  },
};
</script>
