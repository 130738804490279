var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center d-inline-block" }, [
    _c(
      "button",
      {
        staticClass: "btn-circle-sm btn-line",
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [_c("i", { staticClass: "tmlps-icn-delete h5" })]
    ),
    _vm.showText
      ? _c("div", { staticClass: "small text-center" }, [_vm._v("Eliminar")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }