<template>
  <div>
    <b-button class="btn-blue btn-blue-sm" @click="handleChangeImageLogo" :disabled="loading"
      >{{ $t('lang.cambiar.msg') }}</b-button
    >
    <input
      type="file"
      accept="image/png, image/jpeg, image/jpg"
      style="display: none;"
      ref="fileInput"
      @change="handleSelectFile"
    />
  </div>
</template>

<script>
import store from '@/store';
import Errors from '@/util/Errors';

export default {
  name: 'ChangeLogoButton',
  props: {
    project: { type: Object, required: true },
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
    };
  },
  methods: {
    handleChangeImageLogo() {
      this.$refs.fileInput.click();
    },
    async handleSelectFile(event) {
      if (!event.target.files.length) {
        return;
      }

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('project_logo', file);

      this.loading = true;
      try {
        await store.dispatch('project/updateImageProject', {
          project: this.project,
          data: formData,
        });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
          return;
        }
        this.errors.capture({ error: 'Ocurrió un error grave. Por favor intentalo más tarde.' });
        console.error(error);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
