var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-center" },
        [
          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
            _c("h6", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("lang.texto_cambiar.msg")))
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "justify-content-center mt-4" },
        [
          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.changePassword($event)
                  }
                }
              },
              [
                _c(
                  "b-row",
                  { staticClass: "justify-content-center" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c("PasswordInput", {
                          attrs: {
                            label: _vm.$t("lang.contrasenia_actual.msg"),
                            error: _vm.errors.first("password")
                          },
                          model: {
                            value: _vm.form.old_password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "old_password", $$v)
                            },
                            expression: "form.old_password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c("PasswordInput", {
                          attrs: {
                            label: _vm.$t("lang.contrasenia_nueva.msg"),
                            error: _vm.errors.first("password")
                          },
                          model: {
                            value: _vm.form.new_password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "new_password", $$v)
                            },
                            expression: "form.new_password"
                          }
                        })
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn-primary w-100",
                          attrs: { disabled: _vm.loading }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lang.contrasenia_cambiar.msg")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }