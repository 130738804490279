var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-video-table" },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          items: _vm.items,
          fields: _vm.fields,
          "current-page": _vm.currentPage,
          "per-page": 5,
          busy: _vm.loading,
          "show-empty": "",
          "primary-key": "id",
          id: "profile-video-table",
          responsive: "",
          stacked: "md"
        },
        scopedSlots: _vm._u([
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-primary my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.$t("lang.cargando.msg")))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c("h5", { staticClass: "py-5" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lang.no_videos.msg")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "cell(created_at)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(data.item.created))
                ])
              ]
            }
          },
          {
            key: "cell(date_range)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(data.item.date_range))
                ])
              ]
            }
          },
          {
            key: "cell(user_email)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(
                    _vm._s(
                      data.item.creator
                        ? data.item.creator.email
                        : "Usuario eliminado"
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "cell(status)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(_vm._s(_vm.statusLabel(data.item.status)))
                ])
              ]
            }
          },
          {
            key: "cell(available_for)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(
                    _vm._s(data.item.available_for) + " " + _vm._s(_vm.txtDias)
                  )
                ])
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                row.item.status == "success"
                  ? [
                      _c(
                        "button",
                        {
                          key: row.item.id,
                          staticClass:
                            "btn-circle-sm btn-line d-none d-md-inline-block",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "video-player",
                                params: { id: row.item.id }
                              })
                            }
                          }
                        },
                        [_c("i", { staticClass: "tmlps-icn-see-password h5" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn-circle-sm btn-line mx-4 d-none d-md-inline-block",
                          on: {
                            click: function($event) {
                              return _vm.handleVideoDownload(row.item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "tmlps-icn-download h5" })]
                      ),
                      _c("DeleteButton", {
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteVideo(row.item)
                          }
                        }
                      })
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("b-pagination", {
        staticClass: "my-0",
        attrs: {
          "total-rows": _vm.totalRows,
          pills: "",
          "per-page": 5,
          size: "sm",
          align: "right",
          "aria-controls": "profile-video-table"
        },
        model: {
          value: _vm.currentPage,
          callback: function($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage"
        }
      }),
      _c("DeleteVideoModal", {
        attrs: { open: _vm.openDeleteVideoModal, video: _vm.selectedVideo },
        on: {
          close: function($event) {
            _vm.openDeleteVideoModal = false
          },
          deleteVideo: _vm.fetchVideos
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }